import axios from "../../config/http";
import base_axios from "@/config/base_axios";

// initial state
const state = () => ({
    token: null,
    user: null,
    user_copy: null,
    last_course_message: null,
    listen: true,
    can_again: false
})

// getters
const getters = {
    user(state) {
        return state.user
    },
    userFavoriteIds(state) {
        return state.user.favorite.map(e => e.id)
    },
    userCartIds(state) {
        return state.user.cart.map(e=>e.benefit.id)
    },
    userCopy(state) {
        return state.user_copy
    },
    isAuth(state) {
        return state.token && state.user
    },
    token(state) {
        return state.token
    },
    isAdmin(state) {
        return !!state.user.groups.find(e => e.name == 'admin')
    },
    userCoursesId(state) {
        if (state.user) {
            return state.user.courses.map(e=>e.pk)
        }
        return []
    },
    userCourses(state) {
        if (state.user) {
            return state.user.courses
        }
        return []
    },
    mustEditAccount(state) {
        return !state.user.first_name || !state.user.last_name || !state.user.birth_place || !state.user.birthday
    },
    language(state) {
        return state.user.language
    }
}

// actions
const actions = {
    async loginTopt(context, request_data) {
        let { data } = await axios.post('user/token/topt/', request_data);
        if (data.token) {
            axios.defaults.headers.common["Authorization"] = 'Token ' + data.token;
            base_axios.defaults.headers.common["Authorization"] = 'Token ' + data.token;
            localStorage.setItem('token', data.token);
            context.commit('setToken', data.token);
            let user = await axios.get('v2/me/');
            context.commit('setUser', user.data);
            return user;
        } else return data;
    },
    async login(context, request_data) {
        let { data } = await axios.post('user/token/', request_data);
        if (!data.id) {
            axios.defaults.headers.common["Authorization"] = 'Token ' + data.token;
            base_axios.defaults.headers.common["Authorization"] = 'Token ' + data.token;
            localStorage.setItem('token', data.token);
            context.commit('setToken', data.token);
            let user = await axios.get('v2/me/');
            context.commit('setUser', user.data);
            return user;
        } else return data;
    },
    async fetchUser(context) {
        let user = await axios.get('v2/me/')
        context.commit('setUser', user.data)
    },
    async toggleFavorites(ctx, id) {
        let res = await axios.post('v2/toggle-favorite/', {id: id})
        ctx.dispatch('fetchUser')
    },
    async toggleCart(ctx, id) {
        let res = await axios.post('v2/toggle-cart/', {id: id})
        ctx.dispatch('fetchUser')
    },
    checkAuth(context) {
        if(!context.state.token) {
            context.commit('setToken', localStorage.getItem('token'))
        }
    }
}

// mutations
const mutations = {
    setCanAgain(state, value) {
        state.can_again = value
    },
    setListen(state, listen) {
        state.listen = listen
    },
    setLastCourseMessage(state, msg) {
        state.last_course_message = msg
    },
    setToken(state, token) {
        state.token = token
    },
    setUser(state, user) {
        state.user = user
        state.user_copy = user
    },
    editCopyUser(state, data) {
        state.user_copy = {...state.user_copy, ...data}
    },
    logOut(state) {
        localStorage.removeItem('token')
        state.token = null
        state.user = null
        state.user_copy = null
    },
    resetCartItem(state, item) {
        state.user.cart = state.user.cart.map(el=>item.id == el.id? item : el)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}