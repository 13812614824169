<template>

<!--  <router-link to="/" @click="set_home_page(true)"  class=" cursor-pointer fixed z-40 top-5 md:top-4">-->
<!--    <img src="/logo2.png" class="w-24 h-12 xl:w-32 xl:h-16 ml-5" v-if="open">-->
<!--    <img src="/logo1.png" class="w-14 h-14 md:w-16 md:h-16 ml-9 md:ml-8" v-else>-->
<!--  </router-link>-->
  <div :class="`${isSidebarHidden ? 'hidden lg:block' : 'block'}`">
<!--  <div>-->
    <div @click="isShowSidebar = !isShowSidebar; $emit('isShowSidebar', isShowSidebar);" :class="`bg-black text-xl ml-1 h-8 w-8 rounded-full flex items-center justify-center  text-white z-1000 fixed top-20 mt-4 ${isShowSidebar ? 'hidden' : 'block'} lg:hidden`">
      <i :class="`fa fa-arrow-down-short-wide`" aria-hidden="true"></i>
    </div>
    <div
        id="sidebar"
        v-click-outside="close_sidebar"
        :class="`fixed w-16 md:w-24 bg-gradient-to-r from-blur-1 via-blur-2 to-blur-1 m-5 ml-0 lg:ml-4 xl:ml-5 rounded-xl shadow mt-28 transform translate-y-2 bg-blur z-40 ${isShowSidebar ? '' : 'hidden'}`"
    >
      <div @click="isShowSidebar = !isShowSidebar; $emit('isShowSidebar', isShowSidebar);" class="w-full flex items-center justify-center h-8 pt-2 lg:hidden">
        <div class="mx-auto rounded-full bg-white h-6 w-6 flex items-center justify-center">
<!--          <i :class="`fa fa-times text-gray-500`" aria-hidden="true"></i>-->
          <i :class="`fa fa-times text-gray-500`" aria-hidden="true"></i>
        </div>
      </div>
      <div class="flex items-center justify-center mt-2">
        <div
            ref="toggle_sidebar_icon"
            @click="toggleSidebar"
            :class="`${open ? 'justify-start' : 'justify-center'}  w-full justify-center items-center text-center align-middle
              mt-2 cursor-pointer text-gray-500 hover:text-base-500`">
          <div class="w-6 h-6 mx-auto bg-white rounded-full">
            <component :is="open? 'chevron-left-icon' : 'chevron-right-icon'"/>
          </div>
        </div>
      </div>
      <div :class="`flex items-start ${open ? 'border-b border-gray-300' : 'justify-center'} p-4`">
        <div v-if="$store.state.filters.categories.length" v-for="item in [$store.state.filters.categories[0]]"
             :key="item.name">
          <router-link
              to="/"
              @click="select_category(item.id)"
              :class="`flex ${open? 'justify-start' : 'justify-center'} space-x-1.5 px-1 cursor-pointer hover:text-base-600 ${item.id == current_category_id ? 'text-base-500 bg-base-transparent_500' : 'text-gray-600'} hover:bg-white rounded-xl items-center mr-1`"
          >
            <div :class="`text-2xl pl-0.5 ${open? 'w-8' : ''}`">
              <i v-if="item.icon" :class="`fa fa-${item.icon}`" aria-hidden="true"></i>
            </div>
            <p v-if="open" class="pl-0.5">{{ parse_name(item.name) }}</p>
          </router-link>
        </div>

        <!--      <div-->
        <!--          ref="toggle_sidebar_icon"-->
        <!--          @click="open=!open"-->
        <!--          class="bg-white w-5 h-5 justify-center items-center text-center align-middle-->
        <!--             rounded-full mt-2 cursor-pointer text-gray-500 hover:text-base-500">-->
        <!--        <component :is="open? 'chevron-left-icon' : 'chevron-right-icon'"/>-->
        <!--      </div>-->

        <!--      <new-logo id="sidebar-icon" class="w-10 h-10 cursor-pointer" @click="$router.push('/')"/>-->
      </div>

      <div class="pl-2.5">
        <div
            id="categories-list"
            class="flex flex-col pb-4 space-y-1 overflow-y-auto"
        >
          <router-link to="/" v-for="item in $store.state.filters.categories.filter(e=>e.id != $store.state.filters.categories[0].id)"
                       :key="item.name">
            <div
                @click="select_category(item.id); set_home_page(false);"
                :class="`flex ${open? 'justify-start px-1.5' : 'justify-center'} space-x-1.5 cursor-pointer hover:text-base-600 ${item.id == current_category_id ? 'text-base-500 bg-base-transparent_500' : 'text-gray-600'} hover:bg-white rounded-xl items-center mr-2`"
            >
              <div class="text-2xl w-10 text-center">
                <!--              <i v-if="item.icon" :class="`fa fa-${item.icon}`" aria-hidden="true"></i>-->
                <i v-if="item.icon" :class="`fas fa-${item.icon}`" aria-hidden="true"></i>
                <i v-else class="fas fa-circle" aria-hidden="true"></i>
              </div>
              <p v-if="open">{{ parse_name(item.name) }}</p>
            </div>
            <div
                class="bg-gradient-to-r from-transparent via-white to-gray-50 border border-white rounded-2xl mr-4 p-2 font-semibold space-y-2"
                v-if="item.children.length && (item.id == current_category_id || item.children.find(e=>e.id == current_category_id)) && open "
            >
              <div v-for="sub in item.children">
                <div
                    @click="select_category(sub.id)"
                    :class="`flex ${open? 'justify-start' : 'justify-center'} space-x-1.5 px-1.5 cursor-pointer ${sub.id == current_category_id ? 'bg-base-transparent_500 text-base-600' : 'text-gray-600'} hover:text-base-600 hover:bg-base-transparent_500 rounded-xl items-center mr-1`"
                >
                  <p v-if="open">{{ parse_name(sub.name) }}</p>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import newLogo from "@/components/ui/NewLogo";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/vue/solid"
import {ArrowLeftIcon} from "@heroicons/vue/outline"
import gsap from "gsap";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

export default {
  components: {
    newLogo,
    ChevronLeftIcon,
    ChevronRightIcon
  },
  name: "sidebar",
  data() {
    return {
      open: false,
      faIcon: Icon,
      isShowSidebar: true,
      isLoading: false,
      disabledSidebarRoutes: ['/profile', '/suggestion', '/transactions', '/contact', '/reimbursement'],
    }
  },
  // mixins: ['locale'],
  computed: {
    ...mapGetters('filters', ['current_category_id']),
    ...mapState('benefits', ['benefits_type', 'home_page']),
    sorting: {
      get() {
        if (this.sort && this.sort !== '') return this.sort;
        return null;
      },
      set(value) {
        this.select_sort(value)
      }
    },
    isSidebarHidden() {
      let screen_w = window.screen.width
      if (screen_w < 769) {
        if (this.home_page) return true;
        if (this.benefits_type === 'fixed') return true;
        if (this.disabledSidebarRoutes.includes(this.$route.path)) return true;
        else false;
      }
      return false;
    },
  },
  watch: {
    open: async function (val) {
      this.isLoading = true;
      await this.$emit('onToggle', val);
      let screen_w = window.screen.width
      if (val) {
        let sidebar_w = 280

        if (screen_w < 400) {
          sidebar_w = screen_w - 46
        }

        await gsap.to("#sidebar", {width: sidebar_w + 'px', ease: 'elastic', duration: 0.6})
        // gsap.to("#sidebar-icon", {scale: '1.2', ease: 'back', duration: 0.4})
      } else {
        // if (screen_w < 769) gsap.to("#sidebar", {width: '68px', ease: 'back', duration: 0.4});
        if (screen_w < 769) await gsap.to("#sidebar", {width: '64px', ease: 'back', duration: 0.3});
        else await gsap.to("#sidebar", {width: '96px', ease: 'back', duration: 0.3})
      }
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions('filters', ['select_category', 'select_sort']),
    ...mapMutations('benefits', ['set_home_page']),
    ...mapActions('benefits', ['set_benefits_type']),
    close_sidebar() {
      if (!this.isLoading) this.open = false;
    },
    toggleSidebar() {
      if (!this.isLoading) {
        this.open = !this.open;
      }
    },
    parse_name(name) {
      let srb = '';
      let eng = '';
      if (name.includes('/')) {
        eng = name.split('/')[0];
        srb = name.split('/')[1];
        if (this.$store.state.auth.user.language == "SR") {
          return srb;
        } else {
          return eng;
        }
      }
      return name;
    },
  },
  async mounted() {
    this.$store.dispatch('filters/fetch_categories');
  }
}
</script>

<style scoped>
#sidebar {
  height: calc(100vh - 140px);
  overflow: hidden;
}

#categories-list {
  height: calc(100vh - 267px);
}

.z-1000 {
  z-index: 10000;
}

/*
 *  STYLE 7
 */

#categories-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
  border-radius: 4px;
}

#categories-list::-webkit-scrollbar {
  width: 4px;
  background-color: #F5F5F5;
}

#categories-list::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-image: -webkit-gradient(linear,
  left bottom,
  left top,
  color-stop(0.44, theme('colors.base.500')),
  color-stop(0.72, theme('colors.base.600')),
  color-stop(0.86, theme('colors.base.700')));
}
</style>