<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="pb-24 z-20 mt-24 relative">

    <div v-if="benefits_type !== 'fixed'" class="lg:hidden pt-3 mb-4">
      <div class="flex items-center justify-between text-sm font-medium w-full">
        <p class="text-base-600"> {{loc.current_balance}} FLEX </p>
        <p class="text-base-600">{{ monetary(user.balance, user.currency)}}</p>
      </div>

      <div class="w-100 border bg-white h-2 rounded-md">
        <div class="bg-base-600 h-2 rounded-md" :style="`width: ${calculateBudgetPercent(user.spent, user.base_balance)}%`"></div>
      </div>
    </div>

    <!-- TODO: Make this block of code as component  -->
    <div  v-if="$store.state.benefits.home_page" class="relative bg-gray-550 z-30 shadow-xl rounded-xl">
      <!--        <div class="lg:mb-6 mt-2 rounded-xl relative">-->
      <!--          <div class="absolute left-0 top-28 w-full z-20 hidden md:flex items-center justify-between">-->
      <!--            <button @click="goToPrevSlide" :class="`h-9 w-9 rounded-full shadow border border-gray-200 bg-white text-gray-700 transform -translate-x-5 bg-opacity-50 group hover:bg-opacity-75 transition duration-200 ease-in-out`">-->
      <!--              <font-awesome-icon icon="fa-angle-left" :class="`group-hover:text-base-600 font-bold`" />-->
      <!--            </button>-->
      <!--            <button @click="goToNextSlide" :class="`h-9 w-9 rounded-full shadow border border-gray-200 bg-white text-gray-700 transform translate-x-5 bg-opacity-50 group hover:bg-opacity-75 transition duration-200 ease-in-out`">-->
      <!--              <font-awesome-icon icon="fa-angle-right" :class="`group-hover:text-base-600 font-bold`" />-->
      <!--            </button>-->
      <!--          </div>-->
      <!--        </div>-->
      <Splide :options="splideOptions" @move="goToSlide" aria-label="My Favorite Images" class="splide rounded-xl overflow-hidden xl:h-530" ref="slider">
        <SplideSlide>
          <div>
            <img src="../assets/images/banner-img-1.jpg" alt="banner" class="rounded-xl">
          </div>
        </SplideSlide>
        <SplideSlide>
          <div>
            <img src="../assets/images/banner-img-2.jpg" alt="banner" class="rounded-xl">
          </div>
        </SplideSlide>
        <SplideSlide>
          <div>
            <img src="../assets/images/banner-img-3.jpg" alt="banner" class="rounded-xl">
          </div>
        </SplideSlide>
      </Splide>
      <div class="w-full absolute bottom-9 md:bottom-10 lg:bottom-10 text-center">
        <div>
          <button @click="set_benefits_type_check('flexible', 1)" class="bg-base-600 text-white py-2 px-3 md:px-6 lg:px-10 rounded-lg hover:bg-base-500 transition text-sm lg:text-base ease-in-out duration-200 font-medium">{{loc.start_shopping}}</button>
        </div>
        <div class="hidden md:flex items-center justify-center mt-1.5 md:mt-3 lg:mt-3">
          <div class="flex items-center gap-x-8">
            <div @click="goToSlide(0)" :class="`w-3 h-3 rounded-full cursor-pointer ${slideIndex === 0 ? 'bg-base-500' : 'bg-white bg-opacity-50'}`"></div>
            <div @click="goToSlide(1)" :class="`w-3 h-3 rounded-full ${slideIndex === 1 ? 'bg-base-500' : 'bg-white bg-opacity-50'} cursor-pointer`"></div>
            <div @click="goToSlide(2)" :class="`w-3 h-3 rounded-full ${slideIndex === 2 ? 'bg-base-500' : 'bg-white bg-opacity-50'} cursor-pointer`"></div>
          </div>
        </div>
      </div>
      <div class="absolute bottom-0 w-full transform translate-y-6 md:translate-y-5 lg:translate-y-7">
        <div class="w-11/12 lg:w-9/12 flex items-center justify-center bg-white text-base-600 rounded-full mx-auto shadow">
          <button @click="set_benefits_type_check('flexible', 105)" class="w-1/3 h-12 lg:h-14 border-r border-gray-200 rounded-l-full px-1 md:px-0 font-medium hover:bg-base-600 hover:text-white transition ease-in-out duration-200">
            <font-awesome-icon icon="fa-solid fa-users" class="w-6 h-6" />
            <p class="truncate text-xs md:text-sm">Team Building</p>
          </button>
          <button @click="set_benefits_type_check('flexible', 4)" class="w-1/3 h-12 lg:h-14 font-medium hover:bg-base-600 hover:text-white transition ease-in-out duration-200">
            <font-awesome-icon icon="fa-solid fa-graduation-cap" class="h-6 w-6" />
            <p class="truncate text-xs md:text-sm">{{ loc.learning }}</p>
          </button>
          <button @click="set_benefits_type_check('special', 1)" class="group w-1/3 h-12 lg:h-14 space-y-1 border-l border-gray-200 rounded-r-full font-medium hover:bg-base-600 hover:text-white transition ease-in-out duration-200">
            <div class="border-2 border-base-600 group-hover:border-white rounded-full h-5 w-5 p-1 flex items-center mt-1 justify-center mx-auto">
              <font-awesome-icon icon="fa-solid fa-percent" class="h-3 w-3 font-medium text-xs md:text-sm lg:text-base" />
            </div>
            <p class="truncate text-xs md:text-sm">{{ loc.discounts }}</p>
          </button>
        </div>
      </div>
    </div>

    <div v-if="$store.state.benefits.home_page" class="mt-14">
      <div class="flex space-x-4 items-center justify-between mb-2">
        <p class="font-medium text-lg md:text-xl tracking-wide">{{loc.most_popular}}</p>
        <p
            @click="
              toggle_benefit_fetch();
              set_ordering('-');
              select_sort('bought');
              toggle_benefit_fetch();
              select_category($store.state.filters.categories[0].id)"
           class="text-gray-500 font-medium cursor-pointer hover:text-base-500 hover:underline decoration-solid">{{loc.view_all}} ></p>
      </div>
      <benefit-list :benefits="$store.state.benefits.home.popular" type="popular" v-if="$store.state.benefits.home"/>
      <div class="flex space-x-4 items-center justify-between mb-2 mt-10">
        <p class="font-medium text-lg md:text-xl tracking-wide">{{loc.recently_added}}</p>
        <p
            @click="
              toggle_benefit_fetch();
              set_ordering('-');
              select_sort('added');
              toggle_benefit_fetch();
              select_category($store.state.filters.categories[0].id)"
            class="text-gray-500 cursor-pointer hover:text-base-500 font-medium hover:underline decoration-solid">{{loc.view_all}} ></p>
      </div>
      <benefit-list :benefits="$store.state.benefits.home.last" type="last" v-if="$store.state.benefits.home"/>
      <div v-if="recommendedBenefits && recommendedBenefits.length > 0" class="flex space-x-4 items-center justify-between mb-2 mt-10">
        <p class="font-medium text-lg md:text-xl tracking-wide">{{loc.recommended}}</p>
<!--        <p-->
<!--          @click="-->
<!--              toggle_benefit_fetch();-->
<!--              set_ordering('-');-->
<!--              select_sort('bought');-->
<!--              toggle_benefit_fetch();-->
<!--              select_category($store.state.filters.categories[0].id)"-->
<!--          class="text-gray-500 text-sm font-medium cursor-pointer hover:text-base-500 hover:underline decoration-solid">{{loc.view_all}} ></p>-->
      </div>
      <benefit-list :benefits="recommendedBenefits" type="recommended" v-if="$store.state.benefits.home && recommendedBenefits && recommendedBenefits.length > 0"/>
    </div>
    <div v-else class="mt-5 lg:mt-28">
      <p v-if="benefits_type === 'flexible'" class="text-gray-600 mb-6 text-sm">{{loc.flexible_desc}}</p>
      <p v-if="benefits_type === 'special'" class="text-gray-600 mb-6 text-sm">{{loc.special_desc}}</p>
      <p v-if="benefits_type === 'fixed'" class="text-gray-600 mb-6 mt-28 text-sm">{{loc.fix_desc}}</p>

      <additional-filters ref="additional_filters" v-if="benefits_type!='fixed'"/>
      <benefit-list :benefits="$store.state.benefits.benefits.results" v-if="$store.state.benefits.benefits"/>
    </div>
<!--    <pagination :total-pages="20" @change-page="load_mode"></pagination>-->
    <a href="list" name="list" id="list"></a>


    <div v-if="lastFourNotifications.length > 0 && $store.state.benefits.home_page" class="mt-16">
      <div class="flex space-x-4 items-center justify-between mb-2">
        <p class="font-medium text-lg md:text-xl tracking-wide">{{loc.notifications}}</p>
        <p @click="notifications = $store.state.auth.user.notifications; notsModal = true;" class="text-gray-500 cursor-pointer hover:text-base-500 font-medium hover:underline decoration-solid">{{loc.view_all}} ></p>
      </div>
      <div class="md:grid grid-cols-2 gap-10 space-y-4 md:space-y-0">
        <div
          v-for="notification in lastFourNotifications"
          @click="notifications = [notification]; notsModal = true;"
          class="w-full flex items-center gap-x-1 bg-white rounded-md group p-2 md:h-24 md:h-32 lg:h-24 cursor-pointer hover:bg-gray-50 transition duration-200 ease-in-out">
          <div class="w-16 flex items-center justify-center">
            <font-awesome-icon icon="fa-solid fa-comment-dots" class="h-8 w-8 mt-1 text-base-600" />
          </div>
          <div class="w-3/4 font-medium">
            <p class="group-hover:text-base-600">{{ notification.text }}</p>
            <div class="mt-1">
              <p>{{ notification.date }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="$store.state.benefits.home_page" class="mt-16">
      <div class="md:flex items-center justify-between font-medium md:text-lg mb-2 md:mb-4">
        <p class="font-medium text-lg md:text-xl tracking-wide">Because people matter!</p>
        <p class="font-medium text-lg md:text-xl tracking-wide hidden lg:block">{{ loc.ways_of_buying }}</p>
      </div>
      <div class="lg:flex items-start gap-4 select-none mt-2 text-gray-600">
        <div class="bg-gray-200 md:h-700 lg:w-1/2 rounded-md p-0 pb-5 md:p-5">
          <div class="flex items-center justify-center relative transform -translate-x-4">
            <img
              src="../assets/images/app-hob.png"
              alt="HoB Download App"
              class="w-64 mt-6"
            >
            <div class="absolute bottom-5 -right-6 md:right-6 lg:right-0">
              <img
                src="../assets/images/hob-card.png"
                alt="HOB kartica"
                class="w-36 md:w-48 inner-shadow"
              >
            </div>
          </div>
          <div class="flex items-center justify-center gap-x-2 mt-4">
            <font-awesome-icon icon="fa-solid fa-mobile-alt" class="text-xl lg:text-2xl" />
            <p class="ml-1 tracking-tight text-lg md:text-xl text-center lg:text-2xl font-bold">{{ loc.enjoy_the_benefits }}</p>
          </div>
          <p class="flex justify-center mt-6 font-medium text-sm text-center px-4 md:px-0">
            {{ loc.benefits_in_movement }}
          </p>
          <div class="flex justify-center w-full mt-6">
            <a href="https://www.hob.rs" target="_blank" class="text-base-600 font-medium text-sm hover:underline">{{ loc.find_out_more }} ></a>
          </div>
          <!--   TODO: PREVODI       -->
          <div class="w-full mx-auto flex items-center justify-around mt-6">
            <div class="w-36 md:w-40 h-10 rounded-md">
              <a href="https://apps.apple.com/au/app/house-of-benefits/id1497541600" target="_blank" class="">
                <img src="../assets/images/app-store.png" alt="HoB App Store">
              </a>
            </div>
            <div class="w-36 md:w-40 h-10 rounded-md bg-black">
              <a href="https://play.google.com/store/apps/details?id=com.icd.houseofbenefits" target="_blank">
                <img src="../assets/images/google-play.png" alt="HoB App Google Play">
              </a>
            </div>
          </div>
        </div>
        <div class="lg:w-1/2">
          <p class="font-medium text-black text-lg md:text-xl tracking-wide mt-6 mb-2 lg:hidden">{{ loc.ways_of_buying }}</p>
          <div class="bg-gray-200 md:h-700 lg:w-full rounded-md md:mt-4 lg:mt-0 text-gray-600 p-5">
            <ul class="mt-9 font-bold text-2xl space-y-8">
              <li class="flex items-center gap-x-1 md:gap-x-6 rounded-md shadow-xl h-14 md:h-20 bg-gray-50">
                <p class="rounded-full border-2 border-white bg-gray-200 w-9 h-9 md:h-16 md:w-16 ml-3 flex items-center justify-center">1</p>
                <font-awesome-icon icon="fa-solid fa-mobile-alt" class="w-8" />
                <p class="text-base leading-5 md:text-xl">In-Store Shopping</p>
              </li>
              <li class="flex items-center gap-x-1 md:gap-x-6 rounded-md shadow-xl h-14 md:h-20 bg-gray-50">
                <p class="rounded-full border-2 border-white bg-gray-200 w-9 h-9 md:h-16 md:w-16 ml-3 flex items-center justify-center">2</p>
                <font-awesome-icon icon="fa-solid fa-cart-shopping" class="w-8" />
                <p class="text-base leading-5 md:text-xl">Online Shopping</p>
              </li>
              <li class="flex items-center gap-x-1 md:gap-x-6 rounded-md shadow-xl h-14 md:h-20 bg-gray-50">
                <p class="rounded-full border-2 border-white bg-gray-200 w-9 h-9 md:h-16 md:w-16 ml-3 flex items-center justify-center">3</p>
                <!--               <font-awesome-icon icon="fa-solid fa-money-bill-wave" />-->
                <img src="../assets/images/evoucher.png" alt="eVoucher" class="w-9 h-9 pl-0.5 transform fa-rotate-90">
                <p class="transform -translate-x-1 text-base leading-5 md:text-xl">eVoucher</p>
              </li>
              <li class="flex items-center md:gap-x-5 rounded-md shadow-xl h-14 md:h-20 bg-gray-50">
                <p class="rounded-full border-2 border-white bg-gray-200 w-9 h-9 md:h-16 md:w-16 ml-3 flex items-center justify-center">4</p>
                <font-awesome-icon icon="fa-solid fa-file-invoice-dollar" class="w-8 ml-2" />
                <p class="text-base leading-5 md:text-xl">Invoice</p>
              </li>
            </ul>
            <div class="md:flex lg:block xl:flex items-end gap-x-6 mt-10 md:mt-16 lg:mt-5 xl:mt-16 pt-5">
              <div class="md:w-7/12 lg:w-full xl:w-7/12 space-y-4 text-sm lg:text-base">
                <button @click="$router.push('/suggestion')" class="flex items-center w-full ring-2 ring-base-600 px-2 font-medium text-left rounded-md py-2 text-base-600 hover:bg-gray-50 transition duration-200 ease-in-out">
                  <font-awesome-icon icon="fa-solid fa-reply" class="ml-4 h-6 w-6 mr-3" />
                  <span>{{ loc.benefit_suggestion }}</span>
                </button>
                <a href="https://www.linkedin.com/company/house-of-benefits/" target="_blank" class="flex items-center w-full ring-2 ring-base-600 py-2 px-2 font-medium text-left rounded-md text-base-600 hover:bg-gray-50 transition duration-200 ease-in-out">
                  <font-awesome-icon icon="fa-brands fa-linkedin" class="ml-4 h-6 w-6 mr-3" />
                  <p>{{ loc.follow_us }}</p>
                </a>
              </div>
              <div class="md:w-5/12 lg:w-full xl:w-5/12 text-right border mt-4 md:mt-2 xl:mt-0 mb-1 md:mb-0">
                <p class="font-medium tracking-tight">{{ loc.you_have_a_question }}</p>
                <button @click="$router.push('/contact')" class="w-1/2 md:w-40 ml-auto rounded-md bg-base-600 text-white md:px-1 xl:px-4 h-10 flex items-center justify-center gap-x-2 hover:bg-base-500 transition duration-200 ease-in-out">
                  <font-awesome-icon icon="fa-solid fa-comments" />
                  <span class="text-sm">{{ loc.contact_us }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <list-loader v-if="loading" class="absolute top-0 left-0" />
  <modal
    :open="notsModal"
    :hide_submit="true"
    :cancel-text="loc.cancel"
    :title="`${loc.notifications}`"
    @close="notsModal = false; notifications = null;"
    class="transform translate-x-0 md:translate-x-0"
    size="small"
  >
      <notifications-modal
        :is-single-notification="true"
        :notifications="notifications"
      />
  </modal>
</template>

<script>
import { MailIcon, PhoneIcon } from '@heroicons/vue/solid'
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import benefitList from "@/components/benefitList";
import AdditionalFilters from "@/components/additionalFilters";
import pagination from "@/components/pagination";
import notificationsModal from "@/components/notificationsModal";
import modal from "@/components/ui/modal";
import listLoader from "@/components/ui/listLoader";

export default {
  data() {
    return {
      initialPosition: 0,
      items: [],
      itemsPerPage: 21,
      currentPage: 1,
      notsModal: false,
      notifications: [],
      loading: false,
      slideIndex: 0,
      splideOptions: {
        type: 'loop',
        autoplay: true,
        interval: 5000,
        speed: 500,
        // autoWidth: true,
        // autoHeight: true,
        gap: 20,
        perPage: 1,
        pagination: false,
        arrows: false,
        autoScroll: {
          speed: 1,
        },
        drag: 'free',
        mediaQuery: 'min',
      },
    }
  },
  components: {
    AdditionalFilters,
    MailIcon,
    PhoneIcon,
    benefitList,
    pagination,
    listLoader,
    notificationsModal,
    modal,
  },
  methods: {
    ...mapActions('benefits', ['fetchBenefits', 'fetchHome', 'load_mode', 'set_benefits_type']),
    ...mapActions('filters', ['select_category', 'select_sort', 'set_ordering']),
    ...mapMutations('benefits', ['toggle_benefit_fetch']),
    set_benefits_type_check(type, category) {
      if (this.$route.path != '/') {
        this.$router.push('/')
      }
      this.set_benefits_type(type);
      this.select_category(category);
    },
    calculateBudgetPercent(spent, budget) {
      if (budget <= 0) {
        console.error("Budžet mora biti veći od 0.");
        return 0;
      }

      const percentage = (spent / budget) * 100;
      return String(100 - percentage.toFixed(2));
    },
    goToSlide(slide) {
      if (this.$refs['slider']) {
        this.$refs['slider'].splide.go(slide);
        this.slideIndex = slide;
      }
    },
    onSlideChanged(index) {
      this.slideIndex = index;
      console.log(`Trenutni indeks: ${index}`);
    },
  },
  mounted() {
    this.fetchHome()
    const _this = this
    window.onscroll = async () => {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight - 10;
      if (bottomOfWindow && !_this.$store.state.benefits.home_page) {
        if (_this.$route.path == '/' && _this.isAuth && !_this.loading) {
          _this.loading = true;
          await _this.load_mode()
            .then(() => _this.loading = false)
            .catch((e) => _this.loading = false)
            .finally(() => _this.loading = false);
        }
      }
    };
    this.initialPosition = this.$refs?.image?.offsetTop;

    if (this.$refs['slider']) {
      this.$refs['slider'].splide.event.on( 'move', function (index, prev, dest) {
        _this.goToSlide(index);
      } );
    }
  },
  computed: {
    ...mapState('benefits', ['benefits_type']),
    ...mapGetters('auth', ['isAuth', 'user']),
    lastFourNotifications() {
      return this.$store.state.auth.user.notifications.slice(0, 4);
    },
    recommendedBenefits() {
      return this.$store.state.benefits?.home?.recommended.slice(0, 6);
    },
    // displayedItems() {
    //   const start = (this.currentPage - 1) * this.itemsPerPage;
    //   const end = start + this.itemsPerPage;
    //   return this.items.slice(start, end);
    // },
    // totalPages() {
    //   return Math.ceil(this.items.length / this.itemsPerPage);
    // },
  },
}
</script>