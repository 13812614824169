import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'
import axios from './config/http'
import VueAxios from 'vue-axios'
import Datepicker from 'vuejs3-datepicker';
// import Datepicker from 'vue3-date-time-picker';
// import 'vue3-date-time-picker/dist/main.css'
import VueSplide from '@splidejs/vue-splide';

import VueQRCodeComponent from 'vue-qrcode-component'

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@/assets/css/fontawesome/css/font-awesome.min.css';
import locale from "@/mixins/locale";
import debounce from "@/config/debouncer";
// createApp(App).mount('#app')
import vClickOutside from "click-outside-vue3";
// font awesome icons
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import VueFontAwesome from 'vue-font-awesome';
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

import {
  faBell,
  faHeart,
  faCartShopping,
  faUserAlt,
  faUsers,
  faPercent,
  faUpload,
  faGraduationCap,
  faFileInvoiceDollar,
  faMobileScreenButton,
  faAngleLeft,
  faAngleRight,
  faChevronUp,
  faChevronDown,
  faMessage,
  faMobile,
  faMobileAlt,
  faCreditCard,
  faMoneyBillWave,
  faReply,
  faUpLong,
  faDownLong,
  faUser,
  faXmark,
  faComment,
  faCheck,
  faLocationDot,
  faCommentDots,
  faComments,
  faClock,
  faPhone,
  faArrowPointer,
  faEnvelope,
  faGlobe,
  faBuilding,
  faMagnifyingGlassPlus,
  faSliders,
  faHome,
  faCheckCircle,
  faCreditCardAlt,
  faUnlockAlt,
  faDownload,
  faLock,
  faPercentage,
  faArrowsRotate,
  faCircleXmark,
  faArrowDownShortWide,
  faArrowRightFromBracket,
  faGrip,
  faListUl,
  faChevronLeft,
  faChevronRight,

} from "@fortawesome/free-solid-svg-icons";
import { faXmarkCircle } from "@fortawesome/free-regular-svg-icons";
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';


/* add icons to the library */
// library.add(Icon);
library.add(faBell);
library.add(faGrip);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faListUl);
library.add(faCreditCardAlt);
library.add(faCircleXmark);
library.add(faUnlockAlt);
library.add(faArrowDownShortWide);
library.add(faLock);
library.add(faDownload);
library.add(faArrowsRotate);
library.add(faPercentage);
library.add(faCheckCircle);
library.add(faHeart);
library.add(faCartShopping);
library.add(faXmarkCircle);
library.add(faUserAlt);
library.add(faUsers);
library.add(faPercent);
library.add(faGraduationCap);
library.add(faBuilding);
library.add(faFileInvoiceDollar);
library.add(faMobileScreenButton);
library.add(faAngleLeft);
library.add(faAngleRight);
library.add(faChevronUp);
library.add(faEnvelope);
library.add(faGlobe);
library.add(faChevronDown);
library.add(faMessage);
library.add(faArrowPointer);
library.add(faMobile);
library.add(faMobileAlt);
library.add(faCreditCard);
library.add(faPhone);
library.add(faArrowRightFromBracket);
library.add(faMoneyBillWave);
library.add(faLinkedin);
library.add(faReply);
library.add(faUpload);
library.add(faUpLong);
library.add(faDownLong);
library.add(faUser);
library.add(faXmark);
library.add(faComment);
library.add(faCheck);
library.add(faLocationDot);
library.add(faCommentDots);
library.add(faComments);
library.add(faClock);
library.add(faMagnifyingGlassPlus);
library.add(faSliders);
library.add(faHome);




/* import specific icons */
createApp(App)
    // .filter('monetary', monetary)
    .directive('debounce', (el,binding) => debounce(el,binding))
    .mixin(locale)
    .use(store)
    .use(router)
    .use(vClickOutside)
    .use( VueSplide )
    .use(VueViewer)
    .use(VueAxios, axios)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('v-viewer', VueViewer)
    .component('Datepicker', Datepicker)
    // .component('Datepicker', Datepicker)
    .component('qr-code', VueQRCodeComponent)
    .component('QuillEditor', QuillEditor).mount('#app');
