<template>
  <div class="mt-28 mb-14">
    <div class="grid grid-cols-1 lg:grid-cols-3 items-start gap-6">
      <section class="lg:col-span-2">
        <div class="bg-gray-50 rounded-lg h-28 flex items-center p-5 shadow-xl">
          <div class="text-sm text-gray-500 font-medium">
            <p class="text-xl font-semibold text-gray-900">{{ loc.your_cart }} ({{user.cart.length}}):</p>
            <p class="text-sm mt-5">{{ loc.benefits_you_want }}</p>
          </div>
        </div>

        <div class="bg-gray-50 rounded-lg p-5 mt-6 shadow-xl">
          <h3 v-if="user.cart.length > 0" class="font-bold mb-4 text-gray-500">{{ loc.orders }}</h3>
          <h3 v-if="user.cart.length === 0" class="font-bold py-2 text-gray-500">{{ loc.cart_empty }}</h3>
          <ul v-if="user.cart.length > 0" role="list" class="border-t border-b border-gray-200 divide-y divide-gray-200">
            <li v-for="(product, productIdx) in user.cart" :key="product.id" class="flex items-center justify-between py-6 sm:py-10">
              <div class="lg:flex items-center gap-x-5">
                <div class="flex-shrink-0">
                  <img v-if="product.benefit.pictures.length" :src="product.benefit.pictures[0].image" class="w-14 h-10 rounded-md object-center object-cover sm:w-28 sm:h-20" />
                  <div v-else class="bg-gray-300 w-10 h-10 rounded-md object-center object-cover sm:w-20 sm:h-20">

                  </div>
                </div>
                <div>
                  <div class="flex justify-between mt-2 lg:mt-0">
                    <h3 class="text-sm ml-1">
                      <a :href="product.href" class="font-medium text-gray-700 hover:text-gray-800">
                        {{ product.benefit.name }}
                      </a>
                    </h3>
                  </div>
                  <p class="mt-1 text-sm font-bold text-gray-900">{{ monetary(product.benefit.price * (100 - product.benefit.discount) / 100, user.currency) }}</p>
                </div>

              </div>

              <div class="lg:flex items-end gap-x-2 space-y-4 lg:space-y-0 pl-2 lg:pl-8">

                <div class="flex items-center gap-x-1">
                  <button v-if="touchedId === product.id" @click.stop="changed(product.count, product.id)" class="w-8 h-8 border border-base-600 text-base-600 rounded hover:bg-base-600 hover:text-white transition duration-200 ease-in-out">
                    <font-awesome-icon icon="fa-solid fa-check" class="" />
                  </button>
                  <div class="flex items-center justify-around gap-x-1 text-base-600 font-medium h-8 w-32 rounded border border-base-600 hover:bg-white ml-auto lg:ml-0">
                    <button :disabled="loading" class="w-1/3 h-8 rounded-l text-xl hover:bg-base-600 hover:text-white" :class="loading ? 'cursor-wait' : ''" @click="product.count > 1 ? product.count-- : product.count; changed(product.count, product.id);">-</button>
                    <div class="w-1/3 h-8 flex items-center justify-center bg-transparent">
                      <input type="number" v-model="product.count" @keydown="touchedId = product.id" class="bg-transparent w-full text-center border-none outline-none h-6 p-0 m-0">
                    </div>
                    <!--                  <p class="w-1/3 text-center">{{ product.count }}</p>-->
                    <button :disabled="loading" class="w-1/3 h-8 rounded-r text-xl hover:bg-base-600 hover:text-white" :class="loading ? 'cursor-wait' : ''" @click="product.count++; changed(product.count, product.id);">+</button>
                  </div>
                </div>
                <div class="text-right space-y-2 lg:space-y-0 w-32">
                  <p v-if="product.benefit.discount" class="font-medium line-through text-sm">{{ monetary(product.benefit.price * (product.count), user.currency) }}</p>
                  <p v-if="product.benefit.discount" class="font-medium">{{ monetary(product.benefit.price * (100 - product.benefit.discount) / 100 * (product.count), user.currency) }}</p>
                  <p v-if="!product.benefit.discount" class="font-medium">{{ monetary(product.benefit.price * (product.count), user.currency) }}</p>
                  <button @click="toggle_cart(product.benefit.id)" class="text-sm text-red-600 tracking-wide hover:underline">{{ loc.remove_benefit }}</button>
                </div>
<!--                <div class="text-right space-y-2 lg:space-y-0">-->
<!--                  <p v-if="product.benefit.discount" class="font-medium line-through text-sm"> {{monetary(product.benefit.price * (100 - product.benefit.discount) / 100, user.currency) }}</p>-->
<!--                  <p class="font-medium">{{ monetary(product.benefit.price, user.currency) }}</p>-->
<!--                  <button @click="toggle_cart(product.benefit.id)" class="text-sm text-red-600 tracking-wide">Ukloni produkt</button>-->
<!--                </div>-->
              </div>
            </li>
          </ul>
        </div>

      </section>
      <section class="lg:col-span-1 bg-gray-50 p-5 lg:p-8 rounded-lg shadow-xl">
        <h2 id="summary-heading" class="text-lg font-medium text-gray-900">{{loc.order_summary}}</h2>

          <dl class="mt-6 space-y-4">
            <div class="border-t border-gray-200 pt-4 flex items-center justify-between">
              <dt class="flex items-center text-sm text-gray-600">
                <span>{{loc.number_of_benefits}}</span>
              </dt>
              <dd class="text-sm font-medium text-gray-900">{{user.cart.length}}</dd>
            </div>

            <div v-if="understand" class="border-gray-200 text-yellow-500 font-medium text-sm flex items-center justify-between border-t pt-4">
              <dt class="w-8/12  flex items-center">
                <p>{{loc.additional_payroll_tax}}:</p>
                <a class="flex-shrink-0 text-gray-400 hover:text-gray-500">
                  <div class="tooltip mt-1.5">
                    <QuestionMarkCircleIcon class="h-5 w-5"/>
                    <span class="tooltiptext">{{loc.additional_tax}}</span>
                  </div>
                </a>
              </dt>
              <div class="w-4/12 ">
                <dd v-if="additionalTax" class="text-right">{{monetary(total * additionalTax, user.currency)}}</dd>
                <dd v-else-if="additionalTax === 0"  class="text-right">{{monetary(0, user.currency)}}</dd>
                <dd v-else  class="text-right">{{ loc.processing }}...</dd>
              </div>
            </div>

            <div class="border-t border-gray-200 pt-4 flex items-start justify-between">
              <div class="leading-3">
                <dt class="text-base font-medium text-gray-900 pb-0 mb-0">{{loc.total}}</dt>
                <small class="text-xs">{{ loc.vat_is_included }}</small>
              </div>
              <dd class="text-base font-medium text-gray-900">{{monetary(total, user.currency)}}</dd>
            </div>


<!--            <div v-if="understand" class="border-t border-gray-200 pt-4 flex items-center justify-between">-->
<!--              <dt class="flex justify-between text-sm text-gray-600">-->
<!--                <span>{{loc.additional_payrole}}</span>-->
<!--                <a href="#" class="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">-->
<!--                  <div class="tooltip">-->
<!--                    <QuestionMarkCircleIcon class="h-5 w-5"/>-->
<!--                    <span class="tooltiptext">{{loc.additional_tax}}</span>-->
<!--                  </div>-->
<!--                </a>-->
<!--              </dt>-->
<!--            </div>-->

          </dl>
          <div class="relative flex items-start pt-2 mt-5">
            <div class="flex items-center h-5">
              <input v-model="understand" id="comments" aria-describedby="comments-description" name="comments" type="checkbox" class="focus:ring-base-500 h-4 w-4 text-base-600 border-gray-300 rounded" />
            </div>
            <div class="ml-3 text-sm">
              <p id="comments-description" class="text-gray-500">{{loc.checked_cart}}</p>
            </div>
          </div>

          <div class="mt-6">
            <button v-if="!proceedToCheckout" @click="proceedToCheckout = true" :disabled="disabled_button" :class="`w-full ${!disabled_button? 'bg-base-600 hover:bg-base-500 cursor-pointer' : 'bg-gray-500 cursor-not-allowed'} transition duration-200 ease-in-out border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-base-500`">
              {{loc.proceed_to_checkout}}
            </button>
            <button v-if="proceedToCheckout" @click="proceedToCheckout = false" :class="`w-full border border-transparent rounded-md shadow-sm bg-yellow-500 hover:bg-yellow-600 transition duration-200 ease-in-out py-1 mb-2 px-4 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-base-500`">
              {{loc.back_to_cart}}
            </button>
            <button v-if="proceedToCheckout" @click="complete_purchase" :disabled="disabled_button" :class="`w-full ${!disabled_button? 'bg-base-600 hover:bg-base-500 cursor-pointer' : 'bg-gray-500 cursor-not-allowed'} transition duration-200 ease-in-out border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-base-500`">
              {{loc.confirm_purchase}}
            </button>
          </div>
          <p v-if="!user.cart_has_money" class="text-red-500">{{loc.by_error}}</p>
      </section>
    </div>
  </div>
  <custom-modal :open="successModal" submit-text="Save" @close="successModal = false" :has-cancel-button="false" size="regular" class="z-50 flex items-center justify-center">
    <div class="bg-white px-4 md:px-6 py-6 md:py-8 rounded-xl">
      <p class="text-lg md:text-2xl font-bold">{{ loc.transaction_is_successful }}</p>
      <div class="md:flex items-center justify-end gap-x-4 mt-3 text-sm md:text-base">
        <div class="w-full flex justify-end mt-4">
          <button @click="successModal = false; $router.push('/transactions')" class="w-full bg-base-600  hover:bg-base-500 px-4 py-1 md:py-2 text-white font-bold outline-none rounded-md">{{ loc.view_transactions }}</button>
        </div>
        <div class="flex justify-end mt-4">
          <button @click="successModal = false; $router.push('/')" class="w-full border-2 border-base-600 hover:bg-base-600 hover:text-white px-4 py-1 md:py-2 text-base-600 font-bold rounded-md">{{ loc.close }}</button>
        </div>
      </div>
    </div>
  </custom-modal>
</template>

<script>
import { CheckIcon, ClockIcon, QuestionMarkCircleIcon, XIcon } from '@heroicons/vue/solid'
import {mapGetters, mapActions, mapMutations} from "vuex";
import customModal from "@/components/ui/customModal.vue";

export default {
  components: {
    customModal,
    XIcon,
    QuestionMarkCircleIcon
  },
  data() {
    return {
      understand: false,
      count_helper: [],
      loading: false,
      proceedToCheckout: false,
      touchedId: 0,
      successModal: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user'
    }),
    disabled_button() {
      return !this.understand || !this.user.cart_has_money || this.loading || this.total == 0
    },
    total() {
      return this.user.cart.reduce((partialSum, a) => partialSum + Number(a.benefit.price) * (100 - a.benefit.discount) / 100 * a.count, 0)
    },
    additionalTax() {
      let amount = 0;
      const cartLen = this.user.cart.length;
      this.user.cart.forEach((item) => {
        amount += item.benefit.company_price;
      })
      if (isNaN((amount / cartLen) / 100)) {
        return 0;
      }
      console.log(amount, 'amount')
      return (amount / cartLen) / 100;
    }
  },
  methods: {
    ...mapActions('auth', {
      toggle_cart: 'toggleCart',
      fetchUser: 'fetchUser'
    }),
    ...mapMutations('auth', {
      resetCartItem: 'resetCartItem'
    }),
    async changed(count, id) {
      this.touchedId = id;
      if (count < 1) return;
      this.loading = true;
      try {
        let {data} = await this.axios.patch(`v2/cart-items/${id}/`, {count: count})
        await this.fetchUser()
        this.resetCartItem(data)
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
        this.touchedId = 0;
      }
    },
    delayedInput(event, count, id) {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
      }

      this.delayTimer = setTimeout(() => {
        this.changed(event, count, id);
      }, 500); // Postavite željeno vreme kašnjenja u milisekundama
    },
    isNumberKey(event) {
      const key = event.key;
      console.log(key, ' key')

      // check is number
      if (!isNaN(key) && key !== ' ') {
        return true;
      }

      return false;
    },

    async complete_purchase() {
      this.loading = true;
      console.log(this.loading, 'loading start')
      try {
        let { data } = await this.axios.post('v2/complete-purchase/');
        console.log(data, 'data');
        if (data.status === "success") {
          console.log('uspeh')
          this.successModal = true;
          this.set_success(this.loc.transaction_successful);
          this.fetchUser();
          // this.$router.push('/');
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
        this.understand = false;
        this.proceedToCheckout = false;
        console.log(this.loading, 'loading end')
      }
    }
  }
}

</script>

<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: rgba(0, 0, 0, 0.71);
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px;

  /* Position the tooltip */
  position: absolute;
  left: -145px;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

input:focus {
  background-color: transparent;
  color: black;
  border-color: #046c9c;
  outline: none;
  box-shadow: none;
}

/*input {*/
/*    background-color: transparent;*/
/*    border: 0px solid red;*/
/*    border-top: none;*/
/*    border-bottom: none;*/
/*    color: #242323;*/
/*}*/

/*textarea:focus, input:focus{*/
/*  outline: none;*/
/*  border: 0px red;*/
/*  border-top: none;*/
/*  border-bottom: none;*/
/*}*/
</style>