import axios from "@/config/http"
import base_axios from "@/config/base_axios";

const state = () => ({
    benefits: null,
    home: null,
    home_page: true,
    benefits_type: "",
    prevent_fetch_benefits: false,
    selected_providers: []
});

const mutations = {
    toggle_selected_providers(state, provider) {
        if (state.selected_providers.includes(provider)) {
            state.selected_providers = state.selected_providers.filter(e=>e!=provider)
        } else {
            state.selected_providers = [...state.selected_providers, provider]
        }
    },
    clear_selected_providers(state) {
        state.selected_providers = []
    },
    set_benefits_type(state, value) {
        state.benefits_type = value
    },
    switch_home(state) {
        state.home_page = !state.home_page
    },
    set_home(state, home) {
        state.home = home
    },
    set_home_page(state, home) {
        state.home_page = home
    },
    set_benefits(state, benefits) {
        state.benefits = benefits
    },
    toggle_benefit_fetch(state) {
        state.prevent_fetch_benefits = !state.prevent_fetch_benefits
    }
};

const actions = {
    set_benefits_type(ctx, value) {
        ctx.commit('toggle_benefit_fetch')
        ctx.commit('set_benefits_type', value)
        if(value != 'flexible') {
            ctx.dispatch('filters/select_category', null, {root: true})
            ctx.dispatch('filters/set_search', '', {root: true})
            // ctx.dispatch('filters/set_ordering', '+', {root: true})
            // ctx.dispatch('filters/select_sort', 'added', {root: true})
        } else {
            ctx.dispatch('filters/select_category_by_position', 0, {root: true})
        }
        ctx.commit('toggle_benefit_fetch')
        ctx.dispatch('fetchBenefits')

        ctx.commit('filters/set_first_name',
            value == 'special'? 'All Special Offers' : 'All Flexible Benefits',
            { root: true })
    },
    async fetchBenefits(context, url) {
        if (!context.state.prevent_fetch_benefits) {
            let loading = await context.dispatch('ui/toggle_loading', null, { root: true })
            context.commit('set_home_page', false)
            let category = context.getters['category']
            let search = context.getters['search']
            let endpoint = url? url : 'v2/benefit/?'
            let benefit_type = context.state.benefits_type
            let location = context.getters['location']

            endpoint += `ordering=${context.getters['ordering']}${context.getters['sorting']}`
            endpoint += `&benefit_type=${benefit_type}`

            if (context.state.selected_providers.length) {
                endpoint += `&providers=${context.state.selected_providers.join(',')}`
            }

            if (category) {
                endpoint += `&category=${category}`
            }

            if (search) {
                endpoint += `&search=${search}`
            }

            if (location) {
                endpoint += `&location=${location}`
            }

            let benefits = await axios.get(endpoint)
            context.commit('set_benefits', benefits.data)
            await context.dispatch('ui/toggle_loading', loading, { root: true })
        }
    },
    async load_mode(ctx, direction) {
        let endpoint = ctx?.state?.benefits?.next;

        if (endpoint && !ctx.getters['loading']) {
            // let loading = await ctx.dispatch('ui/toggle_loading', null, { root: true })
            let {data} = await base_axios.get(endpoint)
            let new_results = data.results
            data.results = [...ctx.state.benefits.results, ...new_results]
            ctx.commit('set_benefits', data)
            // await ctx.dispatch('ui/toggle_loading', loading, { root: true })
        }
    },
    async fetchHome(context) {
        let loading = await context.dispatch('ui/toggle_loading', null, { root: true })
        let endpoint = 'v2/home/'
        let {data} = await axios.get(endpoint)
        context.commit('set_home', data)
        await context.dispatch('ui/toggle_loading', loading, { root: true })
    }
};
const getters = {
    benefits_type(state) {
        return state.benefits_type
    },
    category(state, getters, rootState, rootGetters) {
        return rootGetters['filters/current_category_id']
    },
    loading(state, getters, rootState, rootGetters) {
        return rootGetters['ui/is_loading']
    },
    sorting(state, getters, rootState, rootGetters) {
        return rootGetters['filters/sorting']
    },
    ordering(state, getters, rootState, rootGetters) {
        return rootGetters['filters/ordering']
    },
    search(state, getters, rootState, rootGetters) {
        return rootGetters['filters/search']
    },
    location(state, getters, rootState, rootGetters) {
        return rootGetters['filters/location']
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}